<template>
  <div class='lobby-hall-page'>
    <div class='lobby-hall relative'>
      <iframe 
        loading='lazy' 
        :src='mainHallVideoUrl'
        frameborder='0' 
        allow='autoplay;' 
        class='z-0'
        style='width:1280px; height:720px;'></iframe>
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'LobbyHallPage',
  props: [
    'mainHallVideoUrl',
  ],
}
</script>

<style lang='scss'>
  .lobby-hall-page {
    overflow-x: auto;
  }

  .lobby-hall {
    width: 1280px; 
    height: 720px;
    overflow-x: auto;
  }
  .lobby-hall {
    iframe {
      box-sizing: content-box;
    }
  }
  .lobby-hall-page-modal .el-dialog.is-fullscreen {
    width: 100vw;
  }
</style>
