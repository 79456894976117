<template>
  <lobby-hall-page :main-hall-video-url='mainHallVideoUrl'>
    <lobby-hall-link v-for='(lobbyLink, index) in lobbyLinks' 
      :key='`lobby-hall-link-${index}`'
      :position-style='lobbyLink.positionStyle'
      :link-bg-color='lobbyLink.linkBgColor'
      :route-name='lobbyLink.routeName'
      :link-path='lobbyLink.linkPath'
      :width='lobbyLink.width'>
      {{ lobbyLink.title }}
    </lobby-hall-link>
  </lobby-hall-page>
</template>

<script>
import LobbyHallPage from '@/components/LobbyHallPage.vue'
import LobbyHallLink from '@/components/LobbyHallLink.vue'

export default {
 name: 'Main',
  components: {
    LobbyHallLink,
    LobbyHallPage,
  },
  data () {
    return {
      mainHallVideoUrl: 'https://player.vimeo.com/video/765360244?h=60111e53ab&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&background=1',
      lobbyLinks: [
        {
          positionStyle: 'top: 20px; right: 20px;',
          linkBgColor: '#456AAB',
          routeName: '',
          linkPath: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kai2022/%5B%EC%98%A8%EB%9D%BC%EC%9D%B8%EC%9A%A9%5D+2022+KAI+International+Meeting.pdf',
          width: '12rem',
          title: 'Abstract Book',
        },
        {
          positionStyle: 'top: 120px; left: calc(50% - 86px);',
          linkBgColor: '#456AAB',
          routeName: 'Sponsors',
          linkPath: '',
          width: '',
          title: 'E-Booth',
        },
        {
          positionStyle: 'top: 207px; left: 260px;',
          linkBgColor: '#456AAB',
          routeName: 'Posters',
          linkPath: '',
          width: '',
          title: 'E-Poster',
        },
        {
          positionStyle: 'top: 346px; left: 100px;',
          linkBgColor: '#456AAB',
          routeName: 'Notices',
          linkPath: '',
          width: '12rem',
          title: 'Notices (공지사항)',
        },
        {
          positionStyle: 'top: 200px; right: 400px;',
          linkBgColor: '#456AAB',
          routeName: 'Programs',
          linkPath: '',
          width: '',
          title: 'Program',
        },
        {
          positionStyle: 'top: 472px; right: 280px;',
          linkBgColor: '#456AAB',
          routeName: 'Welcome',
          linkPath: '',
          width: '12rem',
          title: 'Welcome Message',
        },
        {
          positionStyle: 'top: 510px; left: calc(50% - 162px);',
          linkBgColor: '#456AAB',
          routeName: 'Live',
          linkPath: '',
          width: '12rem',
          title: 'Live Now (강의시청)',
        },
      ]
    }
  },
}
</script>



