<template>
  <div class='link-container'
    :style='positionStyle'
    @click='openLink()'>
    <div 
      class='link-background'
      :style='backgroundStyle'>
      <slot />
      <arrow-narrow-right-icon />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ArrowNarrowRightIcon } from 'vue-tabler-icons'
export default {
  name: 'LobbyHallLink',
  components: {
    ArrowNarrowRightIcon,
  },
  props: [
    'positionStyle',
    'routeName',
    'linkPath',
    'linkBgColor',
    'width',
  ],
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    backgroundColorStyle () {
      if (this.linkBgColor) {
        return `background-color: ${this.linkBgColor};`
      } 
      return this.eventMainThemeColor ? `background-color: ${this.eventMainThemeColor};` : `background-color: #545454;`
    },
    widthStyle () {
      return this.width ? `width: ${this.width};` : 'width: 9rem;'
    },
    backgroundStyle () {
      return `${this.backgroundColorStyle} ${this.widthStyle}`
    },
  },
  methods: {
    openLink () {
      if (this.routeName) {
        this.$router.push({name: this.routeName})
      } else {
        window.open(this.linkPath, '_blank')
      }
      
    },
  }
}
</script>

<style lang='scss' scoped>
  .link-container {
    transition: all 0.2s ease-in-out;
    @apply z-20 absolute cursor-pointer bg-transparent;
    opacity: 0.90;
  }
  .link-container:hover {
    @apply shadow-xl;
    opacity: 1;

    .link-background {
      @apply pr-1;
    }
  }

  .link-background {
    transition: all 0.2s ease-in-out;
    @apply rounded text-left py-2 px-3 w-36 text-white text-sm shadow-md tracking-wide font-bold flex flex-row justify-between items-center border border-solid border-gray-500;
  }
</style>
